import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { page } from "components/page";
import { navigate } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import ReactDOM from 'react-dom';

const setHySSOLogin = gql`
  mutation setHySSOLogin($form: HySSOLoginForm) {
    result: setHySSOLogin(Input: $form) {
      success
    }
  }
`;

@withI18next(["common"])
@page
class HySSOLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
    };
  }

  componentDidMount() {
    this.hySSOLogin(this.props);
  }

  /*componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      if (props.readerStore.auth) {
        navigate("/");
      } else {
        this.hySSOLogin(props);
      }
    }
  }*/

  @autobind
  hySSOLogin(props) {
    let { search } = props;
    let params = { ...search };
    let {
      sid,
    } = params;

    if (sid !== undefined && sid !== "") {
      client.jumperrwdClient
        .mutate({
          mutation: setHySSOLogin,
          variables: {
            form: {
              sid: sid
            },
          },
        })
        .then((res) => {
          let { result } = res.data;
          if (result.success) {
            alert("登入成功");
            this.props.readerStore.syncSessionCookie().then(() => {
              navigate("/");
            });
          } else {
            navigate("/");
          }
        });
    } else {
      navigate("/");
    }
  }


  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default HySSOLogin;
